<template>
  <div class="blockReport">
    <div class="mainBox">
      <el-card shadow="hover" class="mb5 list-filter">
        <el-form :model="filter" label-width="80px" size="mini">
          <el-row type="flex" justify="start" style="flex-wrap: wrap; flex-direction: row">
            <el-col :span="6" style="height: 34px">
              <el-form-item label="Date:" label-width="40px" class="mb5">
                <el-date-picker
                  v-model="filter.date"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="false"
                  class="w100"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="Hour" label-width="45px" class="mb5">
                <el-row>
                  <el-col :span="12" class="pr5">
                    <el-time-select
                      class="w100"
                      v-model="filter.fromHour"
                      :editable="false"
                      :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00',
                      }"
                      placeholder="Select Start Time"
                    ></el-time-select>
                  </el-col>
                  <el-col :span="12" class="pl5">
                    <el-time-select
                      class="w100"
                      v-model="filter.toHour"
                      :editable="false"
                      :picker-options="{
                        start: '00:00',
                        step: '01:00',
                        end: '23:00',
                      }"
                      placeholder="Select End Time"
                    ></el-time-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                filter.columns.indexOf('date') === -1 &&
                  filter.columns.indexOf('hour') === -1 &&
                  filter.columns.indexOf('month') === -1
              "
              :xs="24"
              :sm="24"
              :md="6"
              :lg="6"
              :xl="2"
              style="height: 34px"
            >
              <el-form-item label="compare:" class="mb5">
                <el-checkbox
                  class="color-white"
                  v-model="filterCheckedStatus"
                  label="compare"
                  @change="onChangeCompare()"
                  >.
                </el-checkbox>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                compareSign &&
                  filterCheckedStatus.indexOf('date') === -1 &&
                  filterCheckedStatus.indexOf('hour') === -1
              "
              :span="8"
              style="height: 34px"
            >
              <el-form-item label="Compare Date:" label-width="120px" class="mb5">
                <el-date-picker
                  v-model="filter.compareDate"
                  type="daterange"
                  range-separator="-"
                  start-placeholder="Start Date"
                  end-placeholder="End Date"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  :clearable="true"
                  class="w100"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col
              v-if="filterCheckedStatus.indexOf('compare') === -1"
              :xs="24"
              :sm="6"
              :md="6"
              :lg="6"
              :xl="6"
              style="height: 34px"
            >
              <el-form-item label="Interval:" class="mb5">
                <el-checkbox-group v-model="filter.columns">
                  <el-checkbox label="month">Month</el-checkbox>
                  <el-checkbox label="date">Date</el-checkbox>
                  <el-checkbox label="hour">Hour</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="8">
              <el-form-item label="TimeZone:" class="mb5">
                <el-select v-model="filter.timezone" class="w100">
                  <el-option
                    v-for="item in options.timezone"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Offer(s):" label-width="60px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.offerIds"
                      placeholder="offerIds: eg. 2206264 or 2206264,1567396"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Affiliate(s):" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.affiliateIds"
                      :data-source="affiliatesGroupList"
                      class="w100"
                      multiple
                      :loading="affiliateLoading"
                      clearable
                      reserve-keyword
                      filterable
                      collapse-tags
                      placeholder="Please select Affiliate"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      style="color: #fff"
                      label="affiliate_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Source(s):" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <group-select
                      v-model="filter.sourceIds"
                      :data-source="sourcesList"
                      multiple
                      :loading="sourceLoading"
                      reserve-keyword
                      clearable
                      filterable
                      collapse-tags
                      placeholder="Please select Source"
                      class="w100"
                    />
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="source_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Countries(s):" label-width="90px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input v-model="filter.countries" placeholder="eg. US,IT"></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox v-model="filterCheckedStatus" label="country" class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="Package(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.pkgs"
                      placeholder="eg. com.whatsapp,com.nutclean"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="prod"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="offerName:" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-input
                      v-model="filter.offerName"
                      placeholder="please add offerName"
                    ></el-input>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="offer_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Platform(s)" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="21" :xl="22">
                    <el-select v-model="filter.platform" placeholder="Please select" class="w100">
                      <el-option
                        v-for="item in options.platform"
                        :key="item.value"
                        :value="item.value"
                        :label="item.label"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="24" :lg="3" :xl="2" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="platform"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="5">
              <el-form-item label="Aff_sub(s):" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-input
                      v-model="filter.affSubs"
                      placeholder="eg. carry_i or carry_i,carry_j"
                    ></el-input>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="aff_sub"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="6" v-if="isBDAM">
              <el-form-item label="Sub_rule_id(s):" label-width="100px" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-select
                      v-model="filter.ruleIds"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.subRule"
                        :key="item.ruleId"
                        :label="item.ruleName"
                        :value="item.ruleId"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="rule_id"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="5">
              <el-form-item label="PM(s):" label-width="130px" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-select
                      v-model="filter.sourceManagers"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.sourceManagers"
                        :key="item.username"
                        :label="item.username"
                        :value="item.username"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="source_manager"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="4">
              <el-form-item label="Pid(s):" label-width="50px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="20">
                    <el-select
                      v-model="filter.pids"
                      multiple
                      filterable
                      collapse-tags
                      placeholder="Please select"
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.pidList"
                        :key="item"
                        :label="item"
                        :value="item"
                      >
                      </el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pid"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="4">
              <el-form-item label="Offer_link_type:" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.offerTypes"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                      :disabled="!isBDAM"
                    >
                      <el-option
                        v-for="item in offerTypeOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="6" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="offer_type"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="6" align="left" v-if="isBDAM">
              <el-form-item label="dataSource:" label-width="80px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="19" :lg="19" :xl="18">
                    <el-select
                      v-model="filter.dataSourceIds"
                      placeholder="Please select"
                      multiple
                      filterable
                      collapse-tags
                      clearable
                      class="w100"
                    >
                      <el-option
                        v-for="item in dataSourceList"
                        :key="item.data_source_id"
                        :label="item.data_source_name"
                        :value="item.data_source_id"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="5" :lg="5" :xl="4" align="center">
                    <el-popover
                      placement="right"
                      width="400"
                      v-model="dataSourceFilter"
                      trigger="click"
                    >
                      <svg-icon
                        icon-class="filter"
                        slot="reference"
                        class="mr10 mt10 font-16 cor999"
                      />
                      <el-form
                        ref="dataSourceFilterForm"
                        :model="dataSourceFilterForm"
                        label-width="70px"
                        size="mini"
                      >
                        <el-form-item label="Country" prop="country" class="mb10">
                          <el-select
                            v-model="dataSourceFilterForm.country"
                            size="small"
                            placeholder="Please select"
                            style="width: 100%"
                            filterable
                          >
                            <el-option
                              v-for="item in options.country"
                              :key="item.countryCode"
                              :label="item.countryCode"
                              :value="item.countryCode"
                            />
                          </el-select>
                        </el-form-item>
                        <el-form-item label="OS" prop="os" class="mb10">
                          <el-select
                            v-model="dataSourceFilterForm.os"
                            size="small"
                            placeholder="Please select"
                            style="width: 100%"
                          >
                            <el-option label="IOS" value="ios" />
                            <el-option label="Android" value="android" />
                          </el-select>
                        </el-form-item>
                        <el-form-item class="mb10" align="right">
                          <el-button
                            @click="
                              dataSourceFilterForm.country = '';
                              dataSourceFilterForm.os = '';
                              getDataSourceList();
                            "
                            >重置
                          </el-button>
                          <el-button type="primary" @click="dataSourceFilterSubmit">提交</el-button>
                        </el-form-item>
                      </el-form>
                    </el-popover>
                    <el-checkbox
                      v-model="filterCheckedStatus"
                      label="data_source_id"
                      class="color-white"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="5" align="left" v-if="isBDAM">
              <el-form-item label="pb domain:" label-width="100px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="21" :lg="21" :xl="20">
                    <el-select
                      v-model="filter.pdDomains"
                      placeholder="Please select"
                      filterable
                      collapse-tags
                      clearable
                      class="w100"
                    >
                      <el-option
                        v-for="item in options.pdDomains"
                        :key="item.id"
                        :label="item.pbDomain"
                        :value="item.pbDomain"
                      ></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="3" :lg="3" :xl="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="pb_domain"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="eventName:" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-input
                      v-model="filter.eventName"
                      placeholder="please input eventName"
                    ></el-input>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="event_name"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="BD Name:" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-input
                      v-model="filter.businessList"
                      placeholder="please input BD Name"
                    ></el-input>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox class="color-white" v-model="filterCheckedStatus" label="business"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="BD Group:" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.businessTypes"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="overseas" value="overseas"></el-option>
                      <el-option label="domestic" value="domestic"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="6" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="businessType"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="AM Name:" class="mb5">
                <el-row>
                  <el-col :span="20">
                    <el-input
                      v-model="filter.accountList"
                      placeholder="please input AM Name"
                    ></el-input>
                  </el-col>
                  <el-col :span="4" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="account_manage"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="AM Group:" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.accountTypes"
                      placeholder="Please select"
                      multiple
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="overseas" value="overseas"></el-option>
                      <el-option label="domestic" value="domestic"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="6" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="account_type"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="important:" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.important"
                      placeholder="Please select"
                      clearable
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="important" value="important"></el-option>
                      <el-option label="unimportant" value="unimportant"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="payoutType :" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.offerPayoutType"
                      placeholder="Please select"
                      clearable
                      collapse-tags
                      class="w100"
                    >
                      <el-option label="CPI" value="CPI"></el-option>
                      <el-option label="CPA" value="CPA"></el-option>
                    </el-select>
                  </el-col>
                  <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="6" align="center">
                    <el-checkbox
                      class="color-white"
                      v-model="filterCheckedStatus"
                      label="offer_payout_type"
                      >.
                    </el-checkbox>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="6" :lg="6" :xl="4">
              <el-form-item label="appImportant:" label-width="110px" class="mb5">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="18">
                    <el-select
                      v-model="filter.appImportant"
                      placeholder="Please select appImportant"
                      clearable
                      collapse-tags
                      class="w100"
                      :disabled="!!filter.pkgs"
                    >
                      <el-option label="important" value="important"></el-option>
                    </el-select>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" align="right">
              <el-form-item label-width="0px" class="mb0">
                <el-button
                  type="primary"
                  :loading="loading.search"
                  @click="filterDataSet('Search', 1)"
                  >Search
                </el-button>
                <el-button type="primary" @click="filterDataSet('Download')">Download</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          :data="list"
          v-loading="loading.list"
          ref="blockReportTableRef"
          style="width: 100%"
          size="small"
          border
          highlight-current-row
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'date', order: 'descending' }"
          @sort-change="sortChange"
          @expand-change="expandRowChange"
          max-height="800"
          row-key="id"
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
        >
          <el-table-column
            :key="1"
            v-if="data_source_id_sign"
            label="DataSourceName"
            prop="dataSourceName"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.dataSourceName }}
            </template>
          </el-table-column>
          <el-table-column
            :key="2"
            v-if="date_sign || hour_sign || month_sign"
            label="Date"
            prop="date"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              <!--              {{ scope.row.date }}-->
              {{ month_sign ? formatDate(scope.row.date) : scope.row.date }}
            </template>
          </el-table-column>
          <el-table-column
            :key="17"
            v-if="source_manager_sign"
            label="pm"
            prop="sourceManager"
            fixed
          >
            <template slot-scope="scope">
              {{ scope.row.sourceManager }}
            </template>
          </el-table-column>
          <el-table-column :key="11" v-if="prod_sign" label="Package" prop="prod" fixed>
            <template slot-scope="scope">
              <span v-if="scope.row.appImportant === 'important'">
                <i class="el-icon-star-on" style="color: orange"></i> {{ scope.row.prod }}
              </span>
              <span v-else>
                {{ scope.row.prod }}
              </span>
            </template>
          </el-table-column>
          <el-table-column :key="8" v-if="source_id_sign" label="source" prop="sourceId" fixed>
            <template slot-scope="scope">
              {{ scope.row.sourceId }}
            </template>
          </el-table-column>
          <el-table-column
            :key="9"
            v-if="source_id_sign"
            label="sourceName"
            prop="sourceName"
            fixed
          >
            <template slot-scope="scope">
              <span :style="{ color: scope.row.sourceRate === 'A' ? 'red' : '' }">
                {{ scope.row.sourceName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            :key="109"
            v-if="source_id_sign"
            label="sourceRate"
            prop="sourceName"
            fixed
          >
            <template slot-scope="scope">
              <span :style="{ color: scope.row.sourceRate === 'A' ? 'red' : '' }">
                {{ scope.row.sourceRate }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :key="3"
            v-if="offer_id_sign"
            label="Offer ID"
            prop="offerId"
            fixed
            width="100px"
          >
            <template slot-scope="scope">
              <div style="float: right; align-items: center">
                <span
                  @click="detailClick(scope.row)"
                  class="cor337ab7"
                  style="
                    max-width: 100px;
                    display: inline-block;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                  :title="scope.row.offerStatus"
                  >{{ scope.row.offerId }}</span
                >
                <!-- <i class="el-icon-arrow-down" @click="getSubofferAffilates(scope.row)"></i> -->
                <span
                  v-if="scope.row.offerStatus === 'active'"
                  style="color: green; margin-left: 5px"
                  title="active"
                  >●</span
                >
                <span
                  v-else-if="scope.row.offerStatus === 'hold'"
                  style="color: #ffa500; margin-left: 5px"
                  title="hold"
                  >●</span
                >
                <span
                  v-else-if="scope.row.offerStatus === 'paused'"
                  style="color: red; margin-left: 5px"
                  title="paused"
                  >●</span
                >
                <span
                  v-else-if="scope.row.offerStatus === 'block'"
                  style="color: blue; margin-left: 5px"
                  title="block"
                  >●</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="4"
            v-if="offer_id_sign"
            label="Offer Name"
            prop="offerName"
            fixed
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span @click="detailAutoMixClick(scope.row)" class="cor337ab7">{{
                scope.row.offerName
              }}</span>
            </template>
          </el-table-column>
          <el-table-column :key="5" v-if="checkIn()" label="offer_click cap" prop="offerClickCap">
            <template slot-scope="scope">
              {{ scope.row.offerClickCap }}
            </template>
          </el-table-column>
          <el-table-column
            :key="6"
            v-if="affiliate_id_sign"
            label="affiliate Id"
            prop="affiliateId"
          >
            <template slot-scope="scope">
              {{ scope.row.affiliateId }}
            </template>
          </el-table-column>
          <el-table-column
            :key="7"
            v-if="affiliate_id_sign"
            label="affiliate Name"
            prop="affiliateName"
          >
            <template slot-scope="scope">
              {{ scope.row.affiliateName }}
            </template>
          </el-table-column>
          <el-table-column :key="10" v-if="country_sign" label="countries" prop="country">
            <template slot-scope="scope">
              {{ scope.row.country }}
            </template>
          </el-table-column>
          <el-table-column :key="12" v-if="platform_sign" label="platform" prop="platform">
            <template slot-scope="scope">
              {{ scope.row.platform }}
            </template>
          </el-table-column>
          <el-table-column :key="13" v-if="aff_sub_sign" label="Aff_sub" prop="affSub">
            <template slot-scope="scope">
              {{ scope.row.affSub }}
            </template>
          </el-table-column>
          <el-table-column :key="14" v-if="rule_id_sign" label="ruleId" prop="ruleId">
            <template slot-scope="scope">
              {{ scope.row.ruleId }}
            </template>
          </el-table-column>
          <el-table-column :key="15" v-if="rule_id_sign" label="subCount" prop="subCount">
            <template slot-scope="scope">
              {{ scope.row.subCount }}
            </template>
          </el-table-column>
          <el-table-column
            :key="16"
            v-if="rule_id_sign"
            label="subChangeCount"
            prop="subChangeCount"
          >
            <template slot-scope="scope">
              {{ scope.row.subChangeCount }}
            </template>
          </el-table-column>

          <el-table-column :key="18" v-if="pid_sign" label="Pid" prop="pid">
            <template slot-scope="scope">
              {{ scope.row.pid }}
            </template>
          </el-table-column>
          <el-table-column :key="188" v-if="offer_payout_type" label="offerPayoutType" prop="pid">
            <template slot-scope="scope">
              {{ scope.row.offerPayoutType }}
            </template>
          </el-table-column>
          <el-table-column
            :key="19"
            v-if="offer_type_sign"
            label="Offer Link Type"
            prop="offerType"
          >
            <template slot-scope="scope">
              {{ scope.row.offerType }}
            </template>
          </el-table-column>
          <el-table-column
            :key="20"
            label="pb domain"
            prop="pbDomain"
            v-if="pb_domain_sign"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.pbDomain }}
            </template>
          </el-table-column>
          <el-table-column
            :key="21"
            label="InsRejectTotal"
            prop="insRejectTotal"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span>InsReject<br />Totall</span>
            </template>
            <template slot-scope="scope">
              {{ scope.row.insRejectTotal }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.insRejectTotal, scope.row.old.insRejectTotal),
                  }"
                  >({{ rate(scope.row.insRejectTotal, scope.row.old.insRejectTotal) }}%)</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="22"
            label="Total Clicks"
            prop="totalClickCount"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.totalClickCount }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.totalClickCount, scope.row.old.totalClickCount),
                  }"
                >
                  ({{ rate(scope.row.totalClickCount, scope.row.old.totalClickCount) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="23"
            label="Clicks"
            prop="clickCount"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.clickCount }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.clickCount, scope.row.old.clickCount),
                  }"
                >
                  ({{ rate(scope.row.clickCount, scope.row.old.clickCount) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="123"
            label="Af Clicks"
            prop="afClickCount"
            v-if="prod_sign || pid_sign"
          >
            <template slot-scope="scope">
              {{ scope.row.afClickCount }}
            </template>
          </el-table-column>
          <el-table-column
            :key="24"
            label="ImpressionCount"
            prop="impressionCount"
            align="center"
            sortable="custom"
            width="90px"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot="header">
              <span>Impression<br />Count</span>
            </template>
            <template slot-scope="scope">
              {{ scope.row.impressionCount }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.impressionCount, scope.row.old.impressionCount),
                  }"
                >
                  ({{ rate(scope.row.impressionCount, scope.row.old.impressionCount) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="25"
            v-if="offer_id_sign && affiliate_id_sign"
            label="dailyCap"
            prop="installDailyCap"
          >
            <template slot-scope="scope">
              {{ scope.row.installDailyCap }}
            </template>
          </el-table-column>
          <el-table-column
            :key="26"
            label="install"
            prop="installs"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.installs }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.installs, scope.row.old.installs),
                  }"
                >
                  ({{ rate(scope.row.installs, scope.row.old.installs) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="126"
            label="Af install"
            prop="afInstalls"
            v-if="prod_sign || pid_sign"
          >
            <template slot-scope="scope">
              {{ scope.row.afInstalls }}
            </template>
          </el-table-column>

          <el-table-column :key="27" label="CR" prop="cr">
            <template slot-scope="scope">
              {{ comRate(scope.row.installs, scope.row.clickCount) }}%
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      comRate(scope.row.installs, scope.row.clickCount),
                      comRate(scope.row.old.installs, scope.row.old.clickCount)
                    ),
                  }"
                >
                  ({{
                    rate(
                      comRate(scope.row.installs, scope.row.clickCount),
                      comRate(scope.row.old.installs, scope.row.old.clickCount)
                    )
                  }}%)
                </span>
              </div>
            </template>
          </el-table-column>

          <el-table-column :key="128" label="Af CR" prop="afCr" v-if="prod_sign || pid_sign">
            <template slot-scope="scope">
              {{ comRate(scope.row.afInstalls, scope.row.afClickCount) }}%
            </template>
          </el-table-column>

          <el-table-column :key="28" label="EVR" prop="evr">
            <template slot-scope="scope">
              {{ comRate(scope.row.events, scope.row.installs) }}%
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      comRate(scope.row.events, scope.row.installs),
                      comRate(scope.row.old.events, scope.row.old.installs)
                    ),
                  }"
                >
                  ({{
                    rate(
                      comRate(scope.row.events, scope.row.installs),
                      comRate(scope.row.old.events, scope.row.old.installs)
                    )
                  }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="29"
            label="Event"
            prop="events"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.events }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.events, scope.row.old.events),
                  }"
                >
                  ({{ rate(scope.row.events, scope.row.old.events) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="30" label="installRejectRate">
            <template slot-scope="scope"
              >{{ calcInstallRejectRate(scope.row.insRejectTotal, scope.row.installs) }}%
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      calcInstallRejectRate(scope.row.insRejectTotal, scope.row.installs),
                      calcInstallRejectRate(scope.row.old.insRejectTotal, scope.row.old.installs)
                    ),
                  }"
                >
                  ({{
                    rate(
                      calcInstallRejectRate(scope.row.insRejectTotal, scope.row.installs),
                      calcInstallRejectRate(scope.row.old.insRejectTotal, scope.row.old.installs)
                    )
                  }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="31"
            label="Revenue"
            prop="revenue"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.revenue }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.revenue, scope.row.old.revenue),
                  }"
                >
                  ({{ rate(scope.row.revenue, scope.row.old.revenue) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="32"
            label="Payout"
            prop="payout"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.payout }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.payout, scope.row.old.payout),
                  }"
                >
                  ({{ rate(scope.row.payout, scope.row.old.payout) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="133"
            label="Cost"
            prop="cost"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.cost }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.cost, scope.row.old.cost),
                  }"
                >
                  ({{ rate(scope.row.cost, scope.row.old.cost) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column :key="33" label="EventName" prop="eventName" v-if="event_name_sign">
            <template slot-scope="scope">
              {{ scope.row.eventName }}
            </template>
          </el-table-column>
          <el-table-column :key="34" label="BD Name" prop="business" v-if="business_sign">
            <template slot-scope="scope">
              {{ scope.row.business }}
            </template>
          </el-table-column>
          <el-table-column :key="35" label="BD Group" prop="businessType" v-if="businessType_sign">
            <template slot-scope="scope">
              {{ scope.row.businessType }}
            </template>
          </el-table-column>
          <el-table-column
            :key="36"
            label="AM Name"
            prop="accountManage"
            v-if="account_manage_sign"
          >
            <template slot-scope="scope">
              {{ scope.row.accountManage }}
            </template>
          </el-table-column>
          <el-table-column :key="37" label="AM Group" prop="accountType" v-if="account_type_sign">
            <template slot-scope="scope">
              {{ scope.row.accountType }}
            </template>
          </el-table-column>
          <el-table-column
            :key="38"
            label="Clickflood"
            prop="clickFloods"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.clickFloods }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.clickFloods, scope.row.old.clickFloods),
                  }"
                >
                  ({{ rate(scope.row.clickFloods, scope.row.old.clickFloods) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="39"
            label="Install Hijacking"
            prop="installHijackings"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.installHijackings }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.installHijackings, scope.row.old.installHijackings),
                  }"
                >
                  ({{ rate(scope.row.installHijackings, scope.row.old.installHijackings) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="40"
            label="Bots"
            prop="bots"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.bots }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.bots, scope.row.old.bots),
                  }"
                >
                  ({{ rate(scope.row.bots, scope.row.old.bots) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="56"
            label="aiLayer"
            prop="incorrectSignature"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.incorrectSignature }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.incorrectSignature,
                      scope.row.old.incorrectSignature
                    ),
                  }"
                >
                  ({{ rate(scope.row.incorrectSignature, scope.row.old.incorrectSignature) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="41"
            label="SubReason Timestamp Anomalies"
            prop="botsTimestamp"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.botsTimestamp }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.botsTimestamp, scope.row.old.botsTimestamp),
                  }"
                >
                  ({{ rate(scope.row.botsTimestamp, scope.row.old.botsTimestamp) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="42"
            label="SubReason Bayesian Network"
            prop="botsBayesian"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.botsBayesian }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.botsBayesian, scope.row.old.botsBayesian),
                  }"
                >
                  ({{ rate(scope.row.botsBayesian, scope.row.old.botsBayesian) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="43"
            label="SubReason Device Emulators"
            prop="botsDevice"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.botsDevice }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.botsDevice, scope.row.old.botsDevice),
                  }"
                >
                  ({{ rate(scope.row.botsDevice, scope.row.old.botsDevice) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="44"
            label="SubReason on Behavioral Anomalies"
            prop="subReasonBehavioralAnomalies"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            ssss
          >
            <template slot-scope="scope">
              {{ scope.row.subReasonBehavioralAnomalies }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.subReasonBehavioralAnomalies,
                      scope.row.old.subReasonBehavioralAnomalies
                    ),
                  }"
                >
                  ({{
                    rate(
                      scope.row.subReasonBehavioralAnomalies,
                      scope.row.old.subReasonBehavioralAnomalies
                    )
                  }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="45"
            label="Behavioral Anomalies"
            prop="behavioralAnomalies"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.behavioralAnomalies }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.behavioralAnomalies,
                      scope.row.old.behavioralAnomalies
                    ),
                  }"
                >
                  ({{ rate(scope.row.behavioralAnomalies, scope.row.old.behavioralAnomalies) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="46"
            label="Site Blacklist"
            prop="siteBlacklists"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.siteBlacklists }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.siteBlacklists, scope.row.old.siteBlacklists),
                  }"
                >
                  ({{ rate(scope.row.siteBlacklists, scope.row.old.siteBlacklists) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="47"
            label="Validation Bot"
            prop="validationBots"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.validationBots }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.validationBots, scope.row.old.validationBots),
                  }"
                >
                  ({{ rate(scope.row.validationBots, scope.row.old.validationBots) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="48"
            label="botsFakeInstall"
            prop="botsFakeInstall"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.botsFakeInstall }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.botsFakeInstall, scope.row.old.botsFakeInstall),
                  }"
                >
                  ({{ rate(scope.row.botsFakeInstall, scope.row.old.botsFakeInstall) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="49"
            label="validationHijacking"
            prop="validationHijacking"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.validationHijacking }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.validationHijacking,
                      scope.row.old.validationHijacking
                    ),
                  }"
                >
                  ({{ rate(scope.row.validationHijacking, scope.row.old.validationHijacking) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="50"
            label="ctitAnomalies"
            prop="ctitAnomalies"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.ctitAnomalies }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.ctitAnomalies, scope.row.old.ctitAnomalies),
                  }"
                >
                  ({{ rate(scope.row.ctitAnomalies, scope.row.old.ctitAnomalies) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="51"
            label="inappsBots"
            prop="inappsBots"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.inappsBots }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.inappsBots, scope.row.old.inappsBots),
                  }"
                >
                  ({{ rate(scope.row.inappsBots, scope.row.old.inappsBots) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="52"
            label="AnonymousTraffic"
            prop="anonymousTraffic"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.anonymousTraffic }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(scope.row.anonymousTraffic, scope.row.old.anonymousTraffic),
                  }"
                >
                  ({{ rate(scope.row.anonymousTraffic, scope.row.old.anonymousTraffic) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="53"
            label="DistributionOutlier"
            prop="distributionOutlier"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.distributionOutlier }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.distributionOutlier,
                      scope.row.old.distributionOutlier
                    ),
                  }"
                >
                  ({{ rate(scope.row.distributionOutlier, scope.row.old.distributionOutlier) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="54"
            label="TooManyEngagements"
            prop="tooManyEngagements"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.tooManyEngagements }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.tooManyEngagements,
                      scope.row.old.tooManyEngagements
                    ),
                  }"
                >
                  ({{ rate(scope.row.tooManyEngagements, scope.row.old.tooManyEngagements) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="55"
            label="EngagementInjection"
            prop="engagementInjection"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.engagementInjection }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.engagementInjection,
                      scope.row.old.engagementInjection
                    ),
                  }"
                >
                  ({{ rate(scope.row.engagementInjection, scope.row.old.engagementInjection) }}%)
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :key="57"
            label="MalformedAdvertisingId"
            prop="malformedAdvertisingId"
            align="center"
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
          >
            <template slot-scope="scope">
              {{ scope.row.malformedAdvertisingId }}
              <div v-if="compareSign && scope.row.old">
                <span
                  :style="{
                    color: rateColor(
                      scope.row.malformedAdvertisingId,
                      scope.row.old.malformedAdvertisingId
                    ),
                  }"
                >
                  ({{
                    rate(scope.row.malformedAdvertisingId, scope.row.old.malformedAdvertisingId)
                  }}%)
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <pagination
          class="block pagePanel"
          :pageTotal="pages.total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="pages.pageParam.pageSize"
          :currentPage="pages.pageParam.page"
        ></pagination>
      </el-card>

      <!-- 弹出窗 -->
      <el-dialog title="Auto mix" :visible.sync="offerDetailVisible" top="3vh" width="800px">
        <auto-mix
          v-if="offerDetailVisible"
          ref="addOrUpdate"
          :offerId="offerId"
          :multipleSelection="[]"
        />
        <div align="center" class="pt10">
          <el-button @click="offerDetailVisible = false" size="small">cancel</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
  import BlockReportCon from '../controllers/report/blockReport';

  export default {
    name: 'BlockReport',
    ...BlockReportCon,
  };
</script>

<style lang="scss" scoped></style>
